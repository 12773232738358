import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VehicleDetailsResolver } from '../app/vehicle-details/vehicle-details-resolver.service';
import {VehicleDetailsComponent} from "../app/vehicle-details/vehicle-details.component"


const routes: Routes = [
  {
    path: "form",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "dashboard",
    children: [
      {
        path: "names",
        loadChildren: () =>
          import("./names/names.module").then((m) => m.NamesModule),
      },
    ],
  },
  { path: 'header', loadChildren: () => import('./header/header.module').then(m => m.HeaderModule) },
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'footer', loadChildren: () => import('./footer/footer.module').then(m => m.FooterModule) },
  { path: 'slider', loadChildren: () => import('./slider/slider.module').then(m => m.SliderModule) },
  { path: 'table', loadChildren: () => import('./table/table.module').then(m => m.TableModule) },
  { path: '', loadChildren: () => import('./main-page/main-page.module').then(m => m.MainPageModule) },

  { path: 'dashboard-layout/:page', loadChildren: () => import('./dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule) },
  
  { path: 'login', loadChildren: () => import('./auth_flow/login/login.module').then(m => m.LoginModule) },
  { path: 'verification', loadChildren: () => import('./auth_flow/verification/verification.module').then(m => m.VerificationModule) },
  { path: 'forget_password', loadChildren: () => import('./auth_flow/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: 'reset_password', loadChildren: () => import('./auth_flow/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'password', loadChildren: () => import('./auth_flow/password/password.module').then(m => m.PasswordModule) },
  { path: 'create-account', loadChildren: () => import('./auth_flow/create-account/create-account.module').then(m => m.CreateAccountModule) },
  { path: 'individual-company', loadChildren: () => import('./auth_flow/individual-company/individual-company.module').then(m => m.IndividualCompanyModule) },
  { path: 'contact-qmotor', loadChildren: () => import('./auth_flow/contact-qmotor/contact-qmotor.module').then(m => m.ContactQmotorModule) },

  
  { path: 'notification', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule) },
  { path: 'upload-doc', loadChildren: () => import('./upload-doc/upload-doc.module').then(m => m.UploadDocModule) },

  { path: 'steper', loadChildren: () => import('./steper/steper.module').then(m => m.SteperModule) },
  { path: 'home-search', loadChildren: () => import('./home-search/home-search.module').then(m => m.HomeSearchModule) },
  { path: 'home-popular-brands', loadChildren: () => import('./home-popular-brands/home-popular-brands.module').then(m => m.HomePopularBrandsModule) },
  { path: 'home-featured-ads', loadChildren: () => import('./home-featured-ads/home-featured-ads.module').then(m => m.HomeFeaturedAdsModule) },
  { path: 'home-rent-car', loadChildren: () => import('./home-rent-car/home-rent-car.module').then(m => m.HomeRentCarModule) },
  { path: 'home-auctions-slider', loadChildren: () => import('./home-auctions-slider/home-auctions-slider.module').then(m => m.HomeAuctionsSliderModule) },
  { path: 'home-services', loadChildren: () => import('./home-services/home-services.module').then(m => m.HomeServicesModule) },
  { path: 'home-use-qmotor', loadChildren: () => import('./home-use-qmotor/home-use-qmotor.module').then(m => m.HomeUseQmotorModule) },
  { path: 'home-app-download', loadChildren: () => import('./home-app-download/home-app-download.module').then(m => m.HomeAppDownloadModule) },
  { path: 'home-dealers', loadChildren: () => import('./home-dealers/home-dealers.module').then(m => m.HomeDealersModule) },
  { path: 'home-news', loadChildren: () => import('./home-news/home-news.module').then(m => m.HomeNewsModule) },
  { path: 'post-ad/:current', loadChildren: () => import('./post-ad/post-ad.module').then(m => m.PostAdModule) },
  { path: 'number-plate-ad/:current', loadChildren: () => import('./number-plate-ad/number-plate-ad.module').then(m => m.NumberPlateAdModule) },
  { path: 'number-plate-auction/:current', loadChildren: () => import('./number-plate-auction/number-plate-auction.module').then(m => m.NumberPlateAuctionModule) },
  { path: 'sell-with-qmotor/:current', loadChildren: () => import('./sell-with-qmotor/sell-with-qmotor.module').then(m => m.SellWithQmotorModule) },
  { path: 'vehicle-details-form', loadChildren: () => import('./vehicle-details-form/vehicle-details-form.module').then(m => m.VehicleDetailsFormModule) },
  { path: 'post-auction/:current', loadChildren: () => import('./post-auction/post-auction.module').then(m => m.PostAuctionModule) },

  // { path: 'vehicle-details/:id', loadChildren: () => import('./vehicle-details/vehicle-details.module').then(m => m.VehicleDetailsModule) ,
  //       // data: {
  //       // title:'Vehicle Details',// Static title for now; can be dynamic in the component
  //       // description:'View detailed information about the selected vehicle.'
  //       // } 
  //   },
  {
    path: 'vehicle-details/:id',
    loadChildren: () => import('./vehicle-details/vehicle-details.module').then(m => m.VehicleDetailsModule , ),
    resolve: { vehicleData: VehicleDetailsResolver },
  },


    
  { path: 'auction-details/:id', loadChildren: () => import('./vehicle-details/vehicle-details.module').then(m => m.VehicleDetailsModule) , },
  { path: 'profile-vehicle-details/:id', loadChildren: () => import('./vehicle-details/vehicle-details.module').then(m => m.VehicleDetailsModule),  },
  { path: 'profile-auction-details/:id', loadChildren: () => import('./vehicle-details/vehicle-details.module').then(m => m.VehicleDetailsModule) ,  },
  { path: 'contact-footer', loadChildren: () => import('./contact-footer/contact-footer.module').then(m => m.ContactFooterModule) },
  { path: 'join-auction/:id/:step', loadChildren: () => import('./join-auction/join-auction.module').then(m => m.JoinAuctionModule) },
  { path: 'join-auction-plate-number/:id/:step', loadChildren: () => import('./join-auction/join-auction.module').then(m => m.JoinAuctionModule) },
  { path: 'post-list/:key/:id', loadChildren: () => import('./post-list/post-list.module').then(m => m.PostListModule) },
  { path: 'auction-list/:key/:id', loadChildren: () => import('./post-list/post-list.module').then(m => m.PostListModule) },
  { path: 'list-filter', loadChildren: () => import('./list-filter/list-filter.module').then(m => m.ListFilterModule) },
  { path: 'complete-selling-panner', loadChildren: () => import('./complete-selling-panner/complete-selling-panner.module').then(m => m.CompleteSellingPannerModule) },
  { path: 'news-reviews', loadChildren: () => import('./news-reviews-flow/news-reviews/news-reviews.module').then(m => m.NewsReviewsModule) },
  { path: 'news-reviews/:id', loadChildren: () => import('./news-reviews-flow/news-rivews-details/news-rivews-details.module').then(m => m.NewsRivewsDetailsModule) },
  { path: 'news-reviews/all/:type', loadChildren: () => import('./news-reviews-flow/news-reviews-list/news-reviews-list.module').then(m => m.NewsReviewsListModule) },
  { path: 'news-reviews/all/:type/:id', loadChildren: () => import('./news-reviews-flow/news-reviews-list/news-reviews-list.module').then(m => m.NewsReviewsListModule) },
  { path: 'news-reviews/search/:search', loadChildren: () => import('./news-reviews-flow/news-reviews-list/news-reviews-list.module').then(m => m.NewsReviewsListModule) },
  { path: 'news-reviews/tag/:tag', loadChildren: () => import('./news-reviews-flow/news-reviews-list/news-reviews-list.module').then(m => m.NewsReviewsListModule) },
  { path: 'news-reviews/advice/tag/:tag', loadChildren: () => import('./news-reviews-flow/news-reviews-list/news-reviews-list.module').then(m => m.NewsReviewsListModule) },
  { path: 'services', loadChildren: () => import('./services-flow/services/services.module').then(m => m.ServicesModule) },
  { path: 'dealers', loadChildren: () => import('./dealers-flow/dealers/dealers.module').then(m => m.DealersModule) },
  { path: 'services/add-agent/:id', loadChildren: () => import('./services-flow/add-agent/add-agent.module').then(m => m.AddAgentModule) },
  { path: 'services/provider-details/:id', loadChildren: () => import('./services-flow/provider-details/provider-details.module').then(m => m.ProviderDetailsModule) },
  { path: 'services/:id', loadChildren: () => import('./services-flow/service-details/service-details.module').then(m => m.ServiceDetailsModule) },
  { path: 'dealer-details/:id', loadChildren: () => import('./dealers-flow/dealer-details/dealer-details.module').then(m => m.DealerDetailsModule) },
  { path: 'account/:page', loadChildren: () => import('./account-flow/account/account.module').then(m => m.AccountModule) },
  { path: 'rent-car', loadChildren: () => import('./rent-car-flow/rent-car/rent-car.module').then(m => m.RentCarModule) },
  { path: 'rent-car/:carId', loadChildren: () => import('./rent-car-flow/rent-car/rent-car.module').then(m => m.RentCarModule) },
  { path: 'cars-list', loadChildren: () => import('./rent-car-flow/cars-list/cars-list.module').then(m => m.CarsListModule) },
  { path: 'cars-list/:id', loadChildren: () => import('./rent-car-flow/car-details/car-details.module').then(m => m.CarDetailsModule) },
  { path: 'cars-list-profile/:id', loadChildren: () => import('./rent-car-flow/car-details/car-details.module').then(m => m.CarDetailsModule) },
  { path: 'car-booking', loadChildren: () => import('./rent-car-flow/car-booking/car-booking.module').then(m => m.CarBookingModule) },
  { path: 'compare-vehicles', loadChildren: () => import('./compare-vehicles/compare-vehicles.module').then(m => m.CompareVehiclesModule) },
  { path: 'my-profile', loadChildren: () => import('./account-flow/my-profile/my-profile.module').then(m => m.MyProfileModule) },
  { path: 'account', loadChildren: () => import('./account-flow/account-tabs/account-tabs.module').then(m => m.AccountTabsModule) },
  { path: 'notify-me', loadChildren: () => import('./account-flow/notify-me/notify-me.module').then(m => m.NotifyMeModule) },
  { path: 'upgrade-my-ad/:id', loadChildren: () => import('./account-flow/upgrade-my-ad/upgrade-my-ad.module').then(m => m.UpgradeMyAdModule) },
  { path: 'terms-and-conditions', loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) },
  { path: 'terms-and-conditions/:type', loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) },
  { path: 'crop-images', loadChildren: () => import('./crop-images/crop-images.module').then(m => m.CropImagesModule) },
  { path: 'my-wallet', loadChildren: () => import('./account-flow/my-wallet/my-wallet.module').then(m => m.MyWalletModule) },
  { path: 'google-map', loadChildren: () => import('./google-map/google-map.module').then(m => m.GoogleMapModule) },
  { path: 'edit-auction', loadChildren: () => import('./account-flow/edit-vehicle/edit-vehicle.module').then(m => m.EditVehicleModule) },
  { path: 'edit-ad', loadChildren: () => import('./account-flow/edit-vehicle/edit-vehicle.module').then(m => m.EditVehicleModule) },
  { path: 'edit-auction/:type/:id', loadChildren: () => import('./account-flow/edit-vehicle/edit-vehicle.module').then(m => m.EditVehicleModule) },
  { path: 'edit-ad/:type/:id', loadChildren: () => import('./account-flow/edit-vehicle/edit-vehicle.module').then(m => m.EditVehicleModule) },
  { path: 'post-payment/:id', loadChildren: () => import('./account-flow/post-payment/post-payment.module').then(m => m.PostPaymentModule) },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'careers', loadChildren: () => import('./careers-flow/careers/careers.module').then(m => m.CareersModule) },
  { path: 'number-plates', loadChildren: () => import('./number-plates/number-plates.module').then(m => m.NumberPlatesModule) },
  { path: 'number-plates-auctions', loadChildren: () => import('./number-plates/number-plates.module').then(m => m.NumberPlatesModule) },
  { path: 'faqs', loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'careers/job-details/:id', loadChildren: () => import('./careers-flow/job-details/job-details.module').then(m => m.JobDetailsModule) },
  { path: 'my-plate-card', loadChildren: () => import('./account-flow/my-plate-card/my-plate-card.module').then(m => m.MyPlateCardModule) },
  { path: 'edit-number-plate/:id', loadChildren: () => import('./account-flow/edit-number-plate/edit-number-plate.module').then(m => m.EditNumberPlateModule) },
  { path: 'edit-number-plate-auction/:id', loadChildren: () => import('./account-flow/edit-number-plate/edit-number-plate.module').then(m => m.EditNumberPlateModule) },
  { path: 'google-ads', loadChildren: () => import('./google-ads/google-ads.module').then(m => m.GoogleAdsModule) },
  { path: 'my-rented-cars', loadChildren: () => import('./account-flow/my-rented-cars/my-rented-cars.module').then(m => m.MyRentedCarsModule) },
  { path: 'my-rented-card', loadChildren: () => import('./account-flow/my-rented-card/my-rented-card.module').then(m => m.MyRentedCardModule) },
  
  { path: 'not-found', loadChildren: () => import('./not-found-component/not-found-component.module').then(m => m.NotFoundComponentModule) },
  { path: '**', loadChildren: () => import('./not-found-component/not-found-component.module').then(m => m.NotFoundComponentModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
