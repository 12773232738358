// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
// apiUrl: "https://qmotors-backend-staging.azurewebsites.net/api", // live
apiUrl: "https://qmotors-backend-testing.azurewebsites.net/api", // testing
// apiUrl: "https://qmotor-backend-teststaging.azurewebsites.net/api", // staging
  uploadDoc: "https://webhook.site/1ea6e73c-77aa-4c36-a02d-de957fbfc4a7",
  googleMapsApiKeyDev: "AIzaSyAGrMPX-X4-XLYjbwctcIBXh8NmfhQOWJc",
  // googleMapsApiKeyLive: "AIzaSyAGrMPX-X4-XLYjbwctcIBXh8NmfhQOWJc",


   firebaseConfig : {
    apiKey: "AIzaSyAGrMPX-X4-XLYjbwctcIBXh8NmfhQOWJc",
    authDomain: "q-motor-9e35e.firebaseapp.com",
    databaseURL: "https://q-motor-9e35e-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "q-motor-9e35e",
    storageBucket: "q-motor-9e35e.appspot.com",
    messagingSenderId: "520690318790",
    appId: "1:520690318790:web:ec3959d2f5461e3e4086c7",
    measurementId: "G-JBVZRWY3Q0",
    vapidKey:'BPNqFshnLxHGCjEtcCj79qTMlsZrtwGiAO2fiP9RrFArr4F8k0KZy5QAX44-mMNMXoCroZjf2V9ICr7xDnUr1wM'
  }
};



// export const firebaseConfig = {
//   apiKey: "AIzaSyDEUEJMDvqxcVJXGVjV3gQrJF6E8ZHQyK4",
//   authDomain: "foundation-test-3492a.firebaseapp.com",
//   projectId: "foundation-test-3492a",
//   storageBucket: "foundation-test-3492a.appspot.com",
//   messagingSenderId: "290931349806",
//   appId: "1:290931349806:web:7c2ebbf9d42be0354f02bf",
//   measurementId: "G-BMZTVLG8NY"
// }


export const firebaseConfig =  {
  apiKey: "AIzaSyAGrMPX-X4-XLYjbwctcIBXh8NmfhQOWJc",
  authDomain: "q-motor-9e35e.firebaseapp.com",
  databaseURL: "https://q-motor-9e35e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "q-motor-9e35e",
  storageBucket: "q-motor-9e35e.appspot.com",
  messagingSenderId: "520690318790",
  appId: "1:520690318790:web:ec3959d2f5461e3e4086c7",
  measurementId: "G-JBVZRWY3Q0"
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
