import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Enable production mode if the environment is set to production
if (environment.production) {
  enableProdMode();
}

// Function to bootstrap the Angular application
function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => {
      console.error('Error bootstrapping the application:', err);
      // Optionally, you could send this error to a logging service
    });
}

// Check if the document is fully loaded
if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
