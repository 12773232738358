import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { environment } from "src/environments/environment";
import { BrowserStorageService } from './services/browser-storage.service';
@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  public token: any;
  public lang: any;
  public channelId: any;
  private isBrowser: boolean;

  constructor(
    private notification: NzNotificationService,
    private browserStorage: BrowserStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.lang = this.browserStorage.getItem("lang");
    const channel = this.browserStorage.getItem("channel_id");
    this.channelId = channel || '2';

    if (this.browserStorage.getItem("token") == null) {
      const curl = environment.apiUrl;
      req = req.clone({
        url: `${curl}/${req.url}`,
        setHeaders: {
          lang: this.lang,
          channelId: this.channelId,
          userPlateform: "web",
        },
      });
    } else {
      const token: any = this.browserStorage.getItem("token");
      const curl = environment.apiUrl;

      req = req.clone({
        url: `${curl}/${req.url}`,
        setHeaders: {
          lang: this.lang,
          channelId: this.channelId,
          userPlateform: "web",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }

    // Check if we are in the browser environment before accessing navigator
    if (this.isBrowser && !navigator.onLine) {
      this.browserStorage.setItem("noConnection", "true");
      throw { errors: "No internet connection, please try again!", code: 503 };
    } else {
      this.browserStorage.removeItem("noConnection");
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.browserStorage.clearStorage();
        }
        if (error.status == 404) {
          this.browserStorage.notFound();
        }
        
        let errors = error.error.errors as any;
          // Extract error messages from the errors object
        const errorMessages = Object.values(errors).reduce(
          (acc: any[], errorArray: any) => acc.concat(errorArray),
          []
        );
        const errorMessageText = (errorMessages as string[]).join(", ");

        if (this.isBrowser) {
          this.notification.create("error", "Error", `${errorMessageText}`, {
            nzClass: "danger-notification",
            nzDuration: 5000,
          });
        }

        return throwError(error);
      })
    );
  }
}
