import { Component, ViewEncapsulation, OnInit, Inject ,PLATFORM_ID} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // css manage start
  encapsulation: ViewEncapsulation.None,
  // css manage end
})
export class AppComponent implements OnInit {
  title = "Qmotor";
  
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private translate: TranslateService
  ) {
    translate.addLangs(["en", "ar"]);
    translate.setDefaultLang("en");

    // Check if in the browser before accessing `getBrowserLang`
    if (isPlatformBrowser(this.platformId)) {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang?.match(/en|ar/) ? browserLang : "en");
    }
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      window?.scroll(0, 0);
      document.body.scrollTop = 0;
      document.querySelector('body')?.scrollTo(0, 0);
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      const lang = localStorage.getItem("lang") || "en";
      if (lang === "ar") {
        document.body.classList.add("rtl");
        document.body.classList.remove("ltr");
      } else {
        document.body.classList.add("ltr");
        document.body.classList.remove("rtl");
      }
    }
  }
}
