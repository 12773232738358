import { NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { HttpInterceptorService } from "./http-interceptor.service";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { from, Observable, of } from "rxjs";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import en from "@angular/common/locales/en";
import { DemoNgZorroAntdModule } from "./ng-zorro-antd.module";
// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from '../environments/environment';
import { BrowserStorageService } from './services/browser-storage.service';
import { Inject, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';

import { Injectable } from '@angular/core';


import * as path from 'path';
import { catchError } from "rxjs/operators";

import {VehicleDetailsResolver} from "../app/vehicle-details/vehicle-details-resolver.service"
@Injectable({
  providedIn: 'root',
})
export class WebpackTranslateLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
    private browserStorage: BrowserStorageService
  ) {}

  // getTranslation(lang: string): Observable<any> {
  //   const langLocal = isPlatformBrowser(this.platformId)
  //     ? this.browserStorage.getItem("lang") || "en"
  //     : "en";

  //   // Set default language for SSR
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.browserStorage.setItem("lang", langLocal);
  //   }

  //   const translationUrl = isPlatformBrowser(this.platformId)
  //     ? `/assets/i18n/${langLocal}.json`
  //     : `./dist/browser/assets/i18n/${langLocal}.json`;

  //   return from(fetch(translationUrl).then(res => res.json()));
  // }

  // getTranslation(lang: string): Observable<any> {
  //   const langLocal = this.browserStorage.getItem("lang") || "en";
  //   this.browserStorage.setItem("lang", langLocal);
    
  //   const isServer = !isPlatformBrowser(this.platformId);
  //   const translationUrl = isServer 
  //     ? `${process.cwd()}/dist/browser/assets/i18n/${langLocal}.json`
  //     : `/assets/i18n/${langLocal}.json`;
  
  //   return from(fetch(translationUrl).then(res => res.json()));
  // }

  // getTranslation(lang: string): Observable<any> {
  //   // let translationUrl: string;


  //   const translationUrl = isPlatformBrowser(this.platformId)
  // ? `/assets/i18n/${lang}.json`
  // : `${process.cwd()}/dist/browser/assets/i18n/${lang}.json`;
  //   // Check if in browser environment or server environment
  //   // if (isPlatformBrowser(this.platformId)) {
  //   //   translationUrl = `/assets/i18n/${lang}.json`;
  //   // } else {
  //   //   translationUrl = path.join(process.cwd(), 'dist/browser/assets/i18n', `${lang}.json`);
  //   // }

  //   // Use HttpClient to fetch translation file and handle errors
  //   return this.http.get(translationUrl).pipe(
  //     catchError((error) => {
  //       console.error('Error loading translation file:', error);
  //       return of({}); // Return an empty object if the file isn't found
  //     })
  //   );
  // }
  // getTranslation(lang: string): Observable<any> {
  //   let translationUrl = isPlatformBrowser(this.platformId)
  //     ? `/assets/i18n/${lang}.json`
  //     : `${process.cwd()}/dist/browser/assets/i18n/${lang}.json`;

  //   return this.http.get(translationUrl).pipe(
  //     catchError((error) => {
  //       console.error('Error loading translation file:', error);
  //       return of({}); // Return an empty object on error
  //     })
  //   );
  // }

  getTranslation(lang: string): Observable<any> {
    const translationUrl = `/assets/i18n/${lang}.json`;

    if (isPlatformBrowser(this.platformId)) {
      // In browser environment, use fetch API
      return new Observable(observer => {
        fetch(translationUrl)
          .then(response => response.json())
          .then(data => {
            observer.next(data);
            observer.complete();
          })
          .catch(error => {
            console.error('Error loading translation file:', error);
            observer.next({});
            observer.complete();
          });
      });
    } else {
      // In server environment, use require (make sure you have proper setup for SSR)
      try {
        const translations = require(`../assets/i18n/${lang}.json`);
        return of(translations);
      } catch (error) {
        console.error('Error loading translation file:', error);
        return of({});
      }
    }
  }
}

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    HttpClientJsonpModule,
    DemoNgZorroAntdModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        deps: [HttpClient, PLATFORM_ID, BrowserStorageService]
      }
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
      
    },
    BrowserStorageService,
    // {
    //   provide: VehicleDetailsResolver,
    //   useFactory: (http: HttpClient) => new VehicleDetailsResolver(http),
    //   deps: [HttpClient]
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
