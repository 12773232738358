// import { Injectable } from "@angular/core";
// import {
//   Resolve,
//   ActivatedRouteSnapshot,
//   RouterStateSnapshot,
// } from "@angular/router";

// import { Observable, of } from "rxjs";
// import { catchError, map } from "rxjs/operators";
// import { HttpClient } from "@angular/common/http";


// @Injectable({
//   providedIn: "root",
// })
// export class VehicleDetailsResolver implements Resolve<any> {
//   targetDate: Date = new Date("2024-12-31T23:59:59"); // Set your target date here
//   loading = true;
//   intervalId: any;
//   isAdOwner = false;

//   constructor(private http: HttpClient) {}

//   resolve(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<any> {
//     const slug = route.paramMap.get("id");
//     console.log(slug, "||slug");
//     let link;
//     if (this.isAdOwner) {
//       link = `profile/my-adverts/${slug}`;
//     } else {
//       link = `vehicles/details/${slug}`;
//     }

//     return this.http.get(link).pipe(
//       map((response: any) => {
//         if (response.status?.code === 200) {
//           const data = response.data;
//           return {
//             vehicleData: data.main_data,
//             details: data.details,
//             extraFeatures: data.extra_features,
//             verificationReports: data.verification_reports,
//             loading: false,
//             targetDate: new Date(data.main_data?.expiryDate || this.targetDate),
//           };
//         } else {
//           throw new Error("Vehicle data could not be retrieved.");
//         }
//       }),
//       catchError((error) => {
//         console.error("Error fetching vehicle data", error);
//         return of(null); // Return a fallback value or handle error appropriately
//       })
//     );
//   }
// }

import { Injectable, Injector } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class VehicleDetailsResolver implements Resolve<any> {
  targetDate: Date = new Date("2024-12-31T23:59:59");
  loading = true;
  intervalId: any;
  isAdOwner = false;
  private httpClient: HttpClient;

  constructor(private injector: Injector , ) {
    // Defer HttpClient injection until it’s needed
    this.httpClient = this.injector.get(HttpClient);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const slug = route.paramMap.get("id");
    console.log(slug, "||slug");

    let link = this.isAdOwner
      ? `profile/my-adverts/${slug}`
      : `vehicles/details/${slug}`;

    return this.httpClient.get(link).pipe(
      map((response: any) => {
        if (response.status?.code === 200) {
          const data = response.data;
          return {
            vehicleData: data.main_data,
            details: data.details,
            extraFeatures: data.extra_features,
            verificationReports: data.verification_reports,
            loading: false,
            targetDate: new Date(data.main_data?.expiryDate || this.targetDate),
          };
        } else {
          throw new Error("Vehicle data could not be retrieved.");
        }
      }),
      catchError((error) => {
        console.error("Error fetching vehicle data", error);
        return of(null); // Return a fallback value or handle error appropriately
      })
    );
  }
}

